
import CompTable from "@/components/comp-table.vue"
import Request from "@/module/request/request"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            columns: [
                {
                    title: "借款人",
                    key: "driver.username",
                },
                {
                    title: "借款金额",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.amount ? "0.00" : (params.row.amount / 100).toFixed(2))
                    },
                },
                {
                    title: "还款日期",
                    key: "repaymentDate",
                },
                {
                    title: "审核状态",
                    render: (h: Function, params: obj) => {
                        return { 0: "待审核", 1: "审核通过", 2: "审核未通过" }[params.row.approvalStatus] || "其他"
                    },
                },
                {
                    title: "申请日期",
                    key: "createdDate",
                },
                {
                    title: "操作",
                    fixed: "right",
                    slot: "row-operate",
                    width: 200,
                },
            ],

            type: "apply",
        }
    },

    watch: {
        $route(to: obj) {
            if (to.path === "/loans") {
                this.type = this.$route.query.type || "apply"
                this.$refs.comp_table?.reset()
            }
        },
    },

    activated() {
        this.type = this.$route.query.type || "apply"
        this.$refs.comp_table.reset()
    },

    methods: {
        /**
         * 同意/拒绝申请
         */
        onChangeApply(uuid: string, status: string) {
            Request.post(
                "WEB://LoanRecord/Approve",
                {
                    uuid: uuid,
                    approvalStatus: status,
                },
                {
                    success: "操作成功",
                }
            ).then(() => {
                // 刷新表格
                this.$refs.comp_table.refresh()
            })
        },
    },
}
