import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "table-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Option = _resolveComponent("Option")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Button = _resolveComponent("Button")!
  const _component_comp_table = _resolveComponent("comp-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_comp_table, {
      ref: "comp_table",
      title: $data.type === 'apply' ? '借款登记' : '借款审批',
      columns: $data.columns,
      "table-api": "WEB://LoanRecord/FindAllToPage"
    }, _createSlots({
      search: _withCtx((evt) => [
        _createVNode(_component_Select, {
          modelValue: evt.search.approvalStatus,
          "onUpdate:modelValue": ($event: any) => ((evt.search.approvalStatus) = $event),
          placeholder: "审核状态",
          style: {"width":"200px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([
                            { name: '待审核', value: '0' },
                            { name: '审核通过', value: '1' },
                            { name: '审核未通过', value: '2' },
                        ], (item) => {
              return _createVNode(_component_Option, {
                value: item.value,
                key: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])
      ]),
      operate: _withCtx(() => [
        ($data.type === 'apply')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "info",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/loans/form')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("新增申请")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      ($data.type === 'apply')
        ? {
            name: "row-operate",
            fn: _withCtx((params) => [
              (params.row.approvalStatus === '0')
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    type: "warning",
                    style: {"margin":"3px"},
                    onClick: ($event: any) => (_ctx.$router.push('/meal/form?id=' + params.row.uuid))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("取消申请")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            key: "0"
          }
        : {
            name: "row-operate",
            fn: _withCtx((params) => [
              (params.row.approvalStatus === '0')
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    type: "info",
                    style: {"margin":"3px"},
                    onClick: ($event: any) => ($options.onChangeApply(params.row.uuid, '1'))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("同意")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (params.row.approvalStatus === '0')
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    type: "error",
                    style: {"margin":"3px"},
                    onClick: ($event: any) => ($options.onChangeApply(params.row.uuid, '2'))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("拒绝")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            key: "1"
          }
    ]), 1032, ["title", "columns"])
  ]))
}